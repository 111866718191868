import './App.css';
import Register from './components/Register'
import Login from './components/Login'
import Form from './components/forms/Form'
import ProtectedRoute from './components/ProtectedRoute'
import Dashboard from './components/dashboard'
import { Routes, Route  } from 'react-router-dom'
import UserProvider from './context/user-context'
import Otp from './components/otp'

function App() {
	return (
		<UserProvider>
			<main>
				<Routes>
					<Route path="/" element={ <ProtectedRoute><Dashboard/></ProtectedRoute> } exact />
					<Route path="/dashboard" element={ <ProtectedRoute><Dashboard/></ProtectedRoute> } />
					<Route path="/forms/:formType" element={ <ProtectedRoute><Form/></ProtectedRoute> } />
					<Route path="/otp" element={ <ProtectedRoute><Otp /></ProtectedRoute>}>
					</Route>
					<Route path="/register" element={<Register/>} />
					<Route path="/login" element={<Login/>}/>
				</Routes>
			</main>
		</UserProvider>
	)
}

export default App;
