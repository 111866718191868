import { Row, Form, Alert, Button} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FileInput from '../../common/file-input'
import SelectDynamicOptions from '../../common/select-dynamic-options'
import SelectYesNo from '../../common/select-yes-no'
import MultipleSelect from '../../common/multiple-select-dynamic-options'
import RequiredFieldLabel from '../../common/field-label-required'
import { getCamperFormGettingToKnowData, updateCamperFormGettingToKnowData } from '../../../server-api'
import AlertModalClose from '../../common/alert-modal-close-only'

function GettingToKnow() {
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const [doesRetrieveFail, setDoesRetrieveFail] = useState(false)

    const [preferredName, setPreferredName] = useState('')
    const [camperFullName, setCamperFullName] = useState('')

    const [files, setFiles] = useState(null)
    const [isFilesProvided, setIsFilesProvided] = useState(false)

    const [detailsUpToDate, setDetailsUpToDate] = useState('')
    const [dietaryReqsAllergies, setDietaryReqsAllergies] = useState('')
    const [epilepsy, setEpilepsy] = useState('')

    const [epilepsyManagementPlan, setEpilepsyManagementPlan] = useState(null)
    const [isEpilepsyManagementPlanProvided, setIsEpilepsyManagementPlanProvided] = useState(false)

    const [asthma, setAsthma] = useState('')

    const [asthmaManagementPlan, setAsthmaManagementPlan] = useState(null)
    const [isAsthmaManagementPlanProvided, setIsAsthmaManagementPlanProvided] = useState(false)

    const [anaphylaxis, setAnaphylaxis] = useState('')

    const [anaphylaxisManagementPlan, setAnaphylaxisManagementPlan] = useState(null)
    const [isAnaphylaxisManagementPlanProvided, setIsAnaphylaxisManagementPlanProvided] = useState(false)

    const [abscondAtDay, setAbscondAtDay] = useState('')
    const [abscondAtNight, setAbscondAtNight] = useState('')
    const [medicalInformation, setMedicalInformation] = useState('')
    const [anxietyDepression, setAnxietyDepression] = useState('')
    const [anxietyDepressionTriggers, setAnxietyDepressionTriggers] = useState('')
    const [otherMentalHealthConsideration, setOtherMentalHealthConsideration] = useState('')
    const [behaviourConsequenceOutcome, setBehaviourConsequenceOutcome] = useState('')
    const [visualAids, setVisualAids] = useState([])
    const [mealTimes, setMealTimes] = useState('')

    useEffect(() => {
        getFormData()
    }, [])

    const getFormData = async () => {
        try {
            setIsRetrievingData(true)
            const { data } = await getCamperFormGettingToKnowData()

            setPreferredName(data.preferredName || "")
            setCamperFullName(data.camperFullName || "")

            if(data.files && JSON.parse(data.files).files.length > 0) {
                setIsFilesProvided(true)
            }

            setDetailsUpToDate(data.detailsUpToDate || "")
            setDietaryReqsAllergies(data.dietaryReqsAllergies || "")
            setEpilepsy(data.epilepsy || "")

            if(data.epilepsyManagementPlan && JSON.parse(data.epilepsyManagementPlan).files.length > 0) {
                setIsEpilepsyManagementPlanProvided(true)
            }

            setAsthma(data.asthma || "")

            if(data.asthmaManagementPlan && JSON.parse(data.asthmaManagementPlan).files.length > 0) {
                setIsAsthmaManagementPlanProvided(true)
            }

            setAnaphylaxis(data.anaphylaxis || "")

            if(data.anaphylaxisManagementPlan && JSON.parse(data.anaphylaxisManagementPlan).files.length > 0) {
                setIsAnaphylaxisManagementPlanProvided(true)
            }

            setAbscondAtDay(data.abscondAtDay || "")
            setAbscondAtNight(data.abscondAtNight || "")
            setMedicalInformation(data.medicalInformation || "")
            setAnxietyDepression(data.anxietyDepression || "")
            setAnxietyDepressionTriggers(data.anxietyDepressionTriggers || "")
            setOtherMentalHealthConsideration(data.otherMentalHealthConsideration || "")
            setBehaviourConsequenceOutcome(data.behaviourConsequenceOutcome || "")
            setVisualAids(data.visualAids || [])
            setMealTimes(data.mealTimes || "")
			
            setIsRetrievingData(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
            setDoesRetrieveFail(true)
			setErrorMessage(errorMsg)
            setIsRetrievingData(false)
		}
	}

    const sendFormData = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        try {
			setIsLoading(true)

            const formData = new FormData()

            formData.append("preferredName", preferredName)
            formData.append("camperFullName", camperFullName)
            formData.append("detailsUpToDate", detailsUpToDate)
            formData.append("dietaryReqsAllergies", dietaryReqsAllergies)
            formData.append("epilepsy", epilepsy)
            formData.append("asthma", asthma)
            formData.append("anaphylaxis", anaphylaxis)
            formData.append("abscondAtDay", abscondAtDay)
            formData.append("abscondAtNight", abscondAtNight)
            formData.append("medicalInformation", medicalInformation)
            formData.append("anxietyDepression", anxietyDepression)
            formData.append("anxietyDepressionTriggers", anxietyDepressionTriggers)
            formData.append("otherMentalHealthConsideration", otherMentalHealthConsideration)
            formData.append("behaviourConsequenceOutcome", behaviourConsequenceOutcome)
            formData.append("visualAids", JSON.stringify(visualAids))
            formData.append("mealTimes", mealTimes)

            if(files) {
                formData.append( 
                    "files_file", 
                    files, 
                    files.name 
                )
            }

            if(epilepsyManagementPlan) {
                formData.append( 
                    "epilepsyManagementPlan_file", 
                    epilepsyManagementPlan, 
                    epilepsyManagementPlan.name 
                )
            }

            if(asthmaManagementPlan) {
                formData.append( 
                    "asthmaManagementPlan_file", 
                    asthmaManagementPlan, 
                    asthmaManagementPlan.name 
                )
            }

            if(anaphylaxisManagementPlan) {
                formData.append( 
                    "anaphylaxisManagementPlan_file", 
                    anaphylaxisManagementPlan, 
                    anaphylaxisManagementPlan.name 
                )
            }

			await updateCamperFormGettingToKnowData(formData)
            setShowSuccessModal(true)
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

    if (isRetrievingData) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        )
    } else if (doesRetrieveFail) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <h5>{errorMessage}</h5>
            </div>
        )
    } else {
        return (
            <>
                <AlertModalClose showModal={showSuccessModal} closeModal={ () => setShowSuccessModal(false)}/>
                <h5>Getting to Know You</h5>
                <div style={{ width: "100%"}}>
                    <Form>

                    <Form.Group className="mb-3">
                        <RequiredFieldLabel>Preferred Name</RequiredFieldLabel>
                        <Form.Control type="text" placeholder="Enter text here..." value = { preferredName } onChange = { (event) => { setPreferredName(event.target.value) } }/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Camper Full Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter text here..." value = { camperFullName } onChange = { (event) => { setCamperFullName(event.target.value) } }/>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label style={{marginBottom: "0px"}}>Files</Form.Label>
                        <FileInput file={files} onChange={(event) => setFiles(event.target.files[0])}/>
                        { isFilesProvided ?
                            <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                <span>File has been provided already. You can upload a new one to update.</span>
                            </Alert>
                        : ""}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Details Up To Date</Form.Label>
                        <SelectDynamicOptions 
                            value={detailsUpToDate} 
                            options={["Done", "Working on it", "Stuck"]} 
                            onChange = { (event) =>  { setDetailsUpToDate(event.target.value) } } 
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Dietary Requirements & Allergies</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { dietaryReqsAllergies } onChange = { (event) => { setDietaryReqsAllergies(event.target.value) } }/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Epilepsy</Form.Label>
                        <SelectYesNo value={epilepsy} onChange = { (event) =>  { setEpilepsy(event.target.value) } } />
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label style={{marginBottom: "0px"}}>Epilepsy Management Plan</Form.Label>
                        <FileInput file={epilepsyManagementPlan} onChange={(event) => setEpilepsyManagementPlan(event.target.files[0])}/>
                        { isEpilepsyManagementPlanProvided ?
                            <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                <span>File has been provided already. You can upload a new one to update.</span>
                            </Alert>
                        : ""}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Asthma</Form.Label>
                        <SelectYesNo value={asthma} onChange = { (event) =>  { setAsthma(event.target.value) } } />
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label style={{marginBottom: "0px"}}>Asthma Management Plan</Form.Label>
                        <FileInput file={asthmaManagementPlan} onChange={(event) => setAsthmaManagementPlan(event.target.files[0])}/>
                        { isAsthmaManagementPlanProvided ?
                            <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                <span>File has been provided already. You can upload a new one to update.</span>
                            </Alert>
                        : ""}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Anaphylaxis</Form.Label>
                        <SelectYesNo value={anaphylaxis} onChange = { (event) =>  { setAnaphylaxis(event.target.value) } } />
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label style={{marginBottom: "0px"}}>Anaphylaxis Management Plan</Form.Label>
                        <FileInput file={anaphylaxisManagementPlan} onChange={(event) => setAnaphylaxisManagementPlan(event.target.files[0])}/>
                        { isAnaphylaxisManagementPlanProvided ?
                            <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                <span>File has been provided already. You can upload a new one to update.</span>
                            </Alert>
                        : ""}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Do I abscond during the day?</Form.Label>
                        <SelectYesNo value={abscondAtDay} onChange = { (event) =>  { setAbscondAtDay(event.target.value) } } />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Do I abscond at night?</Form.Label>
                        <SelectYesNo value={abscondAtNight} onChange = { (event) =>  { setAbscondAtNight(event.target.value) } } />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Medical Information</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { medicalInformation } onChange = { (event) => { setMedicalInformation(event.target.value) } }/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Anxiety/Depression</Form.Label>
                        <SelectYesNo value={anxietyDepression} onChange = { (event) =>  { setAnxietyDepression(event.target.value) } } />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Anxiety/Depression Triggers</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { anxietyDepressionTriggers } onChange = { (event) => { setAnxietyDepressionTriggers(event.target.value) } }/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Other Mental Health Considerations</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { otherMentalHealthConsideration } onChange = { (event) => { setOtherMentalHealthConsideration(event.target.value) } }/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Behaviour 2: Consequence/Outcome</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { behaviourConsequenceOutcome } onChange = { (event) => { setBehaviourConsequenceOutcome(event.target.value) } }/>
                    </Form.Group>
                    
                    <Form.Group className="mb-3">
                        <Form.Label>Visual Aids</Form.Label>
                        <MultipleSelect value={visualAids} options={["I use a visual schedule", "I use an communication device", "I use other visual aids", "I use PECS"]} onChange={setVisualAids}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Meal Times</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { mealTimes } onChange = { (event) => { setMealTimes(event.target.value) } }/>
                    </Form.Group>


                    {errorMessage !== "" ? 
                        <Alert style={{padding: "5px"}} variant="danger">
                            <span>{errorMessage}</span>
                        </Alert>
                        : ""}

                        <Button disabled={isLoading} onClick={ (event) => sendFormData(event) } variant="primary" type="button">
                        { isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Submit" }
                        </Button>
                    </Form>
                </div>
            </>

        )
    }
}

export default GettingToKnow;
