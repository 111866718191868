import { Form } from 'react-bootstrap'

function SelectDynamicOptionsValues({ value, options, onChange }) {
    return (
        <Form.Select style={{
            maxWidth: "300px",
            color: value === "" ? "gray" : "black",
        }}
        value={value} onChange = {onChange }>
            <option style={{display: "none"}} value="">Select your answer</option>
            <option value="">---</option>
            {options.map(({option, value}, i) => {     
                return ( 
                    <option key={option} style={{color: "black"}} value={value}>{option}</option>
                )
            })}
        </Form.Select>
    )
}

export default SelectDynamicOptionsValues