import { Form } from 'react-bootstrap'

function RequiredFieldLabel({ children }) {
    return (
        <div style={{ display: "flex", justifyContent: "space-between"}}>
            <Form.Label>{children}</Form.Label>
            <span style={{
                color: "#c4c4c4",
                fontStyle: "italic",
                fontSize: "15px"
            }}>*Required</span>
        </div>
        
    )
}

export default RequiredFieldLabel