import { Col, Container, Row, Card, Button, Form, Alert} from 'react-bootstrap'
import { useContext, useState } from 'react'
import { UserContext } from '../../context/user-context'
import { useNavigate } from "react-router-dom";
import { requestOtp, verifyOtp, validateOtp } from '../../server-api'
import { useCookies } from 'react-cookie'
import QRCode from "react-qr-code";

function Otp() {
    const [verificationNumber, setVerificationNumber] = useState("")
    const [errorMessage, setErrorMessage] = useState('')
    const [otpKey, setOtpKey] = useState('')
    const [qrOtpKey, setQrOtpKey] = useState('')
    const { user, setUser } = useContext(UserContext)
    const [hasRequestedOtp, setHasRequestedOtp] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rememberDevice, setRememberDevice] = useState(false)
    const [, setCookie] = useCookies(['deviceAuth'])

    let navigate = useNavigate()

    const isUserAuthenticated = (user.otpKey !== "" && user.authSetup)

    const requestOtpFn = async() => {
        try {
			setIsLoading(true)
			const { data } = await requestOtp()
            setOtpKey(data.secret)
            setQrOtpKey(data.qrSecret.replace("SecretKey", "FlyingFox"))
			setIsLoading(false)
            setHasRequestedOtp(true)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
    }

    const checkOtpFn = async() => {
        try {
			setIsLoading(true)

            const otpData = {
                otpToken: verificationNumber,
                shouldRemember: rememberDevice
            }

            let response = null
            if(isUserAuthenticated) {
                response = await validateOtp(otpData)
            } else {
                response = await verifyOtp(otpData)
            }
			
            if(response.data.validated) {
                setUser({...user, deviceAuthed : true})

                if(response.data.deviceAuth) {
                    setCookie('deviceAuth', response.data.deviceAuth, {maxAge: 2592000})
                }
                
                navigate('/dashboard')
            } else {
                setErrorMessage("Invalid verification number.")
            }

			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
    }

    const submitHandler = () => {
        setErrorMessage("")
        if(hasRequestedOtp || isUserAuthenticated) {
            checkOtpFn()
        } else {
            requestOtpFn()
        }
    }

    return (
        <Container fluid style= {
			{
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center',
				backgroundColor: '#e9ecef',
				height: '100vh',
				flexDirection: 'column'
			}
		}>
			<Row style={{ width: "auto", textAlign: "center", padding: "20px"}}>
				<Col>
					<h1>{isUserAuthenticated ? "OTP Sign in" : "OTP Registration"}</h1>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<Card style={{ width: "auto", minWidth: "350px" }}>
						<Card.Body>
							<Card.Title style={{ fontSize: "15px", marginBottom: "15px" }}>
                                {!hasRequestedOtp && !isUserAuthenticated ? 
                                    <span>
                                        Protecting your account is our highest priority.<br/>
                                        For your account security, we now require two-factor authentication.
                                    </span>
                                 : "Please fill up fields below to continue"}
                            </Card.Title>
							<Form>
                                {hasRequestedOtp && !isUserAuthenticated ? 
                                    <>
                                        <span>Here is your Google Authenticator key: </span>
                                        <span style={{
                                            display: "block",
                                            fontWeight: "bold",
                                            marginBottom: "10px"
                                        }}>
                                            {otpKey}
                                        </span>
                                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px"}}>
                                            <QRCode size={200} title="Flying Fox" value={qrOtpKey} />
                                        </div>
                                    </>
                                : ""}

                                {isUserAuthenticated ? 
                                    <Form.Check 
                                        type="checkbox"
                                        label="Remember this device for 30 days."
                                        checked={rememberDevice}
                                        onChange={ () => { setRememberDevice(!rememberDevice) }  }
                                    />
                                : ""}

                                {hasRequestedOtp || isUserAuthenticated ? 
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" placeholder="Verification Number" value = { verificationNumber } onChange = { (event) => { setVerificationNumber(event.target.value) } }/>
                                    </Form.Group>
                                : "" }

                                {errorMessage !== "" ? 
								<Alert style={{padding: "5px"}} variant="danger">
									<span>{errorMessage}</span>
								</Alert>
								: ""}

                                <Button disabled={isLoading} onClick={ (event) => submitHandler() } variant="primary" type="button">{isLoading ? "Loading..." : ((hasRequestedOtp || isUserAuthenticated) ? "Verify OTP" : "Setup OTP")}</Button>
                            </Form>
                            <div id="recaptcha-container"> </div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
    )
}

export default Otp;
