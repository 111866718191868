import axios from 'axios'

// const BASE_URL = "https://api-portal-flyingfox-org.au.ngrok.io/api"
const BASE_URL = "https://api-dev-flyingfox.mapps.luxie.tech/api"
export const register = (data) => axios.post(`${BASE_URL}/auth/register`, data)
export const login = (data) => axios.post(`${BASE_URL}/auth/login`, data)
export const verifyOtp = (data) => axios.post(`${BASE_URL}/auth/verify-otp`, data)
export const validateOtp = (data) => axios.post(`${BASE_URL}/auth/validate-otp`, data)
export const requestOtp = () => axios.get(`${BASE_URL}/auth/request-otp`)
export const deviceOtp = (token) => axios.get(`${BASE_URL}/auth/device-otp?token=${token}`)

//Monday Related
//Buddy Forms
export const getBuddyFormAboutData = () => axios.get(`${BASE_URL}/monday/buddy/form/about/get`)
export const updateBuddyFormAboutData = (data) => axios.post(`${BASE_URL}/monday/buddy/form/about/submit`, data)
export const updateBuddyFormCheckData = (data) => axios.post(`${BASE_URL}/monday/buddy/form/check/submit`, data)
export const getBuddyFormCheckData = () => axios.get(`${BASE_URL}/monday/buddy/form/check/get`)

//Camper Forms
export const getCamperFormAboutData = () => axios.get(`${BASE_URL}/monday/camper/form/about/get`)
export const updateCamperFormAboutData = (data) => axios.post(`${BASE_URL}/monday/camper/form/about/submit`, data)
export const getCamperFormGettingToKnowData = () => axios.get(`${BASE_URL}/monday/camper/form/getting-to-know/get`)
export const updateCamperFormGettingToKnowData = (data) => axios.post(`${BASE_URL}/monday/camper/form/getting-to-know/submit`, data)
export const getCamperFormMedicationsData = () => axios.get(`${BASE_URL}/monday/camper/form/medications/get`)
export const addCamperFormMedicationsData = (data) => axios.post(`${BASE_URL}/monday/camper/form/medications/add`, data)
export const updateCamperFormMedicationsData = (data) => axios.post(`${BASE_URL}/monday/camper/form/medications/update`, data)
export const deleteCamperFormMedicationsData = (itemId) => axios.delete(`${BASE_URL}/monday/camper/form/medications/${itemId}/delete`)

export const getBaseUrl = () => {
    return BASE_URL
}