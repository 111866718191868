
import { createContext, useState } from 'react'  

const DEFAULT_VALUE = {
    user: null,
    setUser: (val) => {}
}

export const UserContext = createContext(DEFAULT_VALUE)

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    return (
      <UserContext.Provider value={{user,setUser}}>
        {children}
      </UserContext.Provider>
    )
}

export default UserProvider