import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

function FileInput({ file, onChange }) {
    return (
        <div>
            <div style={{
                height: "35px",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold"
            }}>{file?.name || "No file chosen"}</div>
            <div style={{ position: "relative", width: "150px", overflow: "hidden"}}>
                <div style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "-99999"
                }}>
                    <span style={{ color: "#0085ff"}}><FontAwesomeIcon icon={faPaperclip}/> Select File</span>
                </div>
                <Form.Control style={{ opacity: 0}} type="file" onChange={onChange} />
            </div>
        </div>
    )
}

export default FileInput