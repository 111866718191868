import Select from 'react-select'

function MultipleSelect({ value, options, onChange }) {
    const parsedOptions = options.map((option) => {
        return {
            label: option,
            value: option
        }
    })

    return (
        <Select
            placeholder = "Select your answer"
            isMulti
            options={ parsedOptions}
            defaultValue={
                parsedOptions.filter(option => value?.includes(option.value))
            }
            onChange = { 
                (event) =>  { 
                    const selectedValues = event.map(e => e.value)
                    onChange(selectedValues)
                } 
            } 

        />
    )
}

export default MultipleSelect