import { Col, Row, Form, Alert, Button, InputGroup} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { updateBuddyFormCheckData, getBuddyFormCheckData } from '../../../server-api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { BuddyConsentReleaseDisclaimer, BuddyConsentVolunteerCodeOfConduct, BuddyConsentPhotography } from './consents'
import FileInput from '../../common/file-input'
import RequiredFieldLabel from '../../common/field-label-required'
import AlertModalClose from '../../common/alert-modal-close-only'

function Check() {
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const [doesRetrieveFail, setDoesRetrieveFail] = useState(false)

    const [childrensCheckFile, setChildrensCheckFile] = useState(null)
    const [childrensCheckCardNumber, setChildrensCheckCardNumber] = useState('')
    const [childrensCheckExpiryDate, setChildrensCheckExpiryDate] = useState('')
    const [isChildrenCheckFileProvided, setIsChildrenCheckFileProvided] = useState(false)

    const [policeCheckFile, setPoliceCheckFile] = useState(null)
    const [policeCheckRefNumber, setPoliceCheckRefNumber] = useState('')
    const [policeCheckExpiryDate, setpoliceCheckExpiryDate] = useState('')
    const [isPoliceCheckFileProvided, setIsPoliceCheckFileProvided] = useState(false)

    const [ndisScreenCheck, setNdisScreenCheck] = useState(false)

    const [consentReleaseDisclaimerCheck, setConsentReleaseDisclaimerCheck] = useState(false)
    const [consentReleaseDisclaimerDate, setConsentReleaseDisclaimerDate] = useState('')
    const [consentVolunteerCodeOfConductCheck, setConsentVolunteerCodeOfConductCheck] = useState(false)
    const [consentVolunteerCodeOfConductDate, setConsentVolunteerCodeOfConductDate] = useState('')
    const [consentPhotographyCheck, setConsentPhotographyCheck] = useState(false)
    const [consentPhotographyDate, setConsentPhotographyDate] = useState('')

    useEffect(() => {
        getFormData()
    }, [])

    const getFormData = async () => {
        try {
            setIsRetrievingData(true)
			const { data } = await getBuddyFormCheckData()
            setChildrensCheckCardNumber(data.childrensCheckCardNumber || "")
            setChildrensCheckExpiryDate(data.childrensCheckExpiryDate || "")

            setPoliceCheckRefNumber(data.policeCheckRefNumber || "")
            setpoliceCheckExpiryDate(data.policeCheckExpiryDate || "")
            setNdisScreenCheck(data.ndisScreenCheck === "true")

            setConsentReleaseDisclaimerDate(data.consentReleaseDisclaimerDate || "")
            setConsentVolunteerCodeOfConductDate(data.consentVolunteerCodeOfConductDate || "")
            setConsentPhotographyDate(data.consentPhotographyDate  || "")

            if(data.consentReleaseDisclaimerDate) {
                setConsentReleaseDisclaimerCheck(true)
            }

            if(data.consentVolunteerCodeOfConductDate) {
                setConsentVolunteerCodeOfConductCheck(true)
            }

            if(data.consentPhotographyDate) {
                setConsentPhotographyCheck(true)
            }

            if(data.childrensCheckFile && JSON.parse(data.childrensCheckFile).files.length > 0) {
                setIsChildrenCheckFileProvided(true)
            }

            if(data.policeCheckFile && JSON.parse(data.policeCheckFile).files.length > 0) {
                setIsPoliceCheckFileProvided(true)
            }
            
            setIsRetrievingData(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
            setDoesRetrieveFail(true)
			setErrorMessage(errorMsg)
            setIsRetrievingData(false)
		}
	}

    const sendFormData = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        try {
			setIsLoading(true)

            const formData = new FormData()

            formData.append("childrensCheckCardNumber", childrensCheckCardNumber)
            formData.append("childrensCheckExpiryDate", childrensCheckExpiryDate)

            formData.append("policeCheckRefNumber", policeCheckRefNumber)
            formData.append("policeCheckExpiryDate", policeCheckExpiryDate)

            formData.append("ndisScreenCheck", ndisScreenCheck)

            formData.append("consentReleaseDisclaimerCheck", consentReleaseDisclaimerCheck)
            formData.append("consentReleaseDisclaimerDate", consentReleaseDisclaimerDate)

            formData.append("consentVolunteerCodeOfConductCheck", consentVolunteerCodeOfConductCheck)
            formData.append("consentVolunteerCodeOfConductDate", consentVolunteerCodeOfConductDate)

            formData.append("consentPhotographyCheck", consentPhotographyCheck)
            formData.append("consentPhotographyDate", consentPhotographyDate)

            if(childrensCheckFile) {
                formData.append( 
                    "children_file", 
                    childrensCheckFile, 
                    childrensCheckFile.name 
                )
            }
            
            if(policeCheckFile) {
                formData.append( 
                    "police_file", 
                    policeCheckFile, 
                    policeCheckFile.name 
                )
            }

            await updateBuddyFormCheckData(formData)
            setShowSuccessModal(true)
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

    if (isRetrievingData) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        )
    } else if (doesRetrieveFail) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <h5>{errorMessage}</h5>
            </div>
        )
    } else {
        return (
            <div style={{ width: "100%"}}>
                <AlertModalClose showModal={showSuccessModal} closeModal={ () => setShowSuccessModal(false)}/>
                <Form>
                    <Row style={{marginBottom: "50px"}}>
                        <Col>
                            <h5>Working with Childrens Check</h5>
                            <Form.Group as={Row}>
                                <RequiredFieldLabel style={{marginBottom: "0px"}}>Upload a photo of your card</RequiredFieldLabel>
                                <FileInput file={childrensCheckFile} onChange={(event) => setChildrensCheckFile(event.target.files[0])}/>
                                { isChildrenCheckFileProvided ?
                                    <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                        <span>Photo has been provided already. You can upload a new one to update.</span>
                                    </Alert>
                                : ""}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Card Number</RequiredFieldLabel>
                                <Form.Control type="number" placeholder="Enter text here..." value = { childrensCheckCardNumber } onChange = { (event) => { setChildrensCheckCardNumber(event.target.value) } }/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Expiry Date</RequiredFieldLabel>
                                <Form.Control type="date" value = { childrensCheckExpiryDate } onChange = { (event) => { setChildrensCheckExpiryDate(event.target.value) } }/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "50px"}}>
                        <Col>
                            <h5>Police Check</h5>
                            <Form.Group as={Row}>
                                <RequiredFieldLabel style={{marginBottom: "0px"}}>Upload a photo of your Police Check</RequiredFieldLabel>
                                <FileInput file={policeCheckFile} onChange={(event) => setPoliceCheckFile(event.target.files[0])}/>
                                { isPoliceCheckFileProvided ?
                                    <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                        <span>Photo has been provided already. You can upload a new one to update.</span>
                                    </Alert>
                                : ""}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Reference Number</RequiredFieldLabel>
                                <Form.Control type="number" placeholder="Enter text here..." value = { policeCheckRefNumber } onChange = { (event) => { setPoliceCheckRefNumber(event.target.value) } }/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Expiry Date</RequiredFieldLabel>
                                <Form.Control type="date" value = { policeCheckExpiryDate } onChange = { (event) => { setpoliceCheckExpiryDate(event.target.value) } }/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "35px"}}>
                        <Col>
                            <h5>NDIS Worker Screener Check</h5>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Have you completed the NDIS Worker Screener Check?"
                                    checked = {ndisScreenCheck}
                                    onChange={ (event) => { setNdisScreenCheck(event.target.checked) }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "50px"}}>
                        <Col>
                            <h5>Release and Disclaimer</h5>
                            <BuddyConsentReleaseDisclaimer />
                            <Form.Group style={{marginTop: "15px", display: "flex", justifyContent: "space-between"}} className="mb-3">
                                <Form.Check 
                                    style={{display: "inline-block"}}
                                    type="checkbox"
                                    label="Signature"
                                    checked = {consentReleaseDisclaimerCheck}
                                    onChange={ (event) => { setConsentReleaseDisclaimerCheck(event.target.checked) }}
                                />
                                <div style={{display: "inline-block"}}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Date Signed</InputGroup.Text>
                                        <Form.Control type="date" value = { consentReleaseDisclaimerDate } onChange = { (event) => { setConsentReleaseDisclaimerDate(event.target.value) } }/>
                                    </InputGroup>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "50px"}}>
                        <Col>
                            <h5>Code of Conduct</h5>
                            <BuddyConsentVolunteerCodeOfConduct />
                            <Form.Group style={{marginTop: "15px", display: "flex", justifyContent: "space-between"}} className="mb-3">
                                <Form.Check 
                                    style={{display: "inline-block"}}
                                    type="checkbox"
                                    label="Signature"
                                    checked = {consentVolunteerCodeOfConductCheck}
                                    onChange={ (event) => { setConsentVolunteerCodeOfConductCheck(event.target.checked) }}
                                />
                                <div style={{display: "inline-block"}}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Date Signed</InputGroup.Text>
                                        <Form.Control type="date" value = { consentVolunteerCodeOfConductDate } onChange = { (event) => { setConsentVolunteerCodeOfConductDate(event.target.value) } }/>
                                    </InputGroup>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "50px"}}>
                        <Col>
                            <h5>Photography Consent </h5>
                            <BuddyConsentPhotography />
                            <Form.Group style={{marginTop: "15px", display: "flex", justifyContent: "space-between"}} className="mb-3">
                                <Form.Check 
                                    style={{display: "inline-block"}}
                                    type="checkbox"
                                    label="Signature"
                                    checked = {consentPhotographyCheck}
                                    onChange={ (event) => { setConsentPhotographyCheck(event.target.checked) }}
                                />
                                <div style={{display: "inline-block"}}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Date Signed</InputGroup.Text>
                                        <Form.Control type="date" value = { consentPhotographyDate } onChange = { (event) => { setConsentPhotographyDate(event.target.value) } }/>
                                    </InputGroup>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            {errorMessage !== "" ? 
                                <Alert style={{padding: "5px"}} variant="danger">
                                    <span>{errorMessage}</span>
                                </Alert>
                            : ""}
                            <Button disabled={isLoading} onClick={ (event) => sendFormData(event) } variant="primary" type="button">
                                { isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Submit" }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

export default Check;
