import FormBuddyAboutMe from "./buddy/AboutMe"
import FormBuddyCheck from "./buddy/Check"
import CamperAboutMe from './camper/AllAboutMe'
import CamperGettingToKnow from './camper/GettingToKnow'
import CamperMedications from './camper/Medications'
import { Col, Container, Row} from 'react-bootstrap'
import Navbar from '../common/navbar'
import React, { useContext } from 'react'
import { UserContext } from '../../context/user-context'
import { Navigate, useParams } from 'react-router-dom'

const renderContent = (formType) => {
    if(formType === "buddy-about") {
        return <FormBuddyAboutMe />
    } else if (formType === "buddy-check") {
        return <FormBuddyCheck />
    } else if (formType === "camper-about") {
        return <CamperAboutMe />
    } else if (formType === "camper-getting-to-know") {
        return <CamperGettingToKnow />
    } else if (formType === "camper-medications") {
        return <CamperMedications />
    }
}

function Form() {
    const { user } = useContext(UserContext)
    const { formType } = useParams()

    return !user.deviceAuthed ? <Navigate to="/otp" /> : (
        <Container>
            <Navbar />
            <Row style={{ marginTop: "50px"}}>
                <Col>
                    <div style={{ display: "flex", justifyContent: "center"}}>
                        <div style={{ width:"100%", maxWidth: "800px"}}>
                            {renderContent(formType)}
                        </div>
                    </div>
                </Col>
            </Row>
            
        </Container>
    )
}

export default Form;