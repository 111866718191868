import { Row, Button, Form, Alert } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { getCamperFormAboutData, updateCamperFormAboutData } from '../../../server-api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import '../styles/PhoneInput.css'
import FileInput from '../../common/file-input'
import SelectYesNo from '../../common/select-yes-no'
import RequiredFieldLabel from '../../common/field-label-required'
import AlertModalClose from '../../common/alert-modal-close-only'

function AllAboutMe() {
	const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const [doesRetrieveFail, setDoesRetrieveFail] = useState(false)

    const [fullName, setFullName] = useState('')
    const [primaryContactName, setPrimaryContactName] = useState('')
    const [primaryContactEmail, setPrimaryContactEmail] = useState('')
    const [primaryContactPhone, setPrimaryContactPhone] = useState('')
    const [primaryContactAddress, setPrimaryContactAddress] = useState('')
    const [primaryContactRelation, setPrimaryContactRelation] = useState('')
    const [secondaryContactName, setSecondaryContactName] = useState('')
    const [secondaryContactEmail, setSecondaryContactEmail] = useState('')
    const [secondaryContactPhone, setSecondaryContactPhone] = useState('')
    const [secondaryContactRelation, setSecondaryContactRelation] = useState('')
    const [medicareNumber, setMedicareNumber] = useState('')
    const [medicareExpiryDate, setMedicareExpiryDate] = useState('')
    const [medicarePositionOnCard, setMedicarePositionOnCard] = useState('')

    const [privateHealthInsuranceNumber, setPrivateHealthInsuranceNumber] = useState('')
    const [privateHealthInsuranceExpiryDate, setPrivateHealthInsuranceExpiryDate] = useState('')
    const [privateHealthInsurancePositionOnCard, setPrivateHealthInsurancePositionOnCard] = useState('')

    const [ambulanceCoverNumber, setAmbulanceCoverNumber] = useState('')
    const [ambulancePositionOnCard, setAmbulancePositionOnCard] = useState('')
    const [haveAllergies, setHaveAllergies] = useState('')
    const [allergies, setAllergies] = useState('')
    const [allergiesSeverity, setAllergiesSeverity] = useState('')
    const [allergiesSymptoms, setAllergiesSymptoms] = useState('')
    const [isAnaphylaxis, setIsAnaphylaxis] = useState('')

    const [anaphylaxisManagementPlan, setAnaphylaxisManagementPlan] = useState(null)
    const [isAnaphylaxisManagementPlanProvided, setIsAnaphylaxisManagementPlanProvided] = useState(false)

    const [haveEpilepsySeizures, setHaveEpilepsySeizures] = useState('')
    const [lastSeizures, setLastSeizures] = useState('')

    const [epilepsyManagementPlan, setEpilepsyManagementPlan] = useState(null)
    const [isEpilepsyManagementPlanProvided, setIsEpilepsyManagementPlanProvided] = useState(false)

    const [haveDiabetes, setHaveDiabetes] = useState('')
    
    const [diabetesManagementPlan, setDiabetesManagementPlan] = useState(null)
    const [isDiabetesManagementPlanProvided, setIsDiabetesManagementPlanProvided] = useState(false)

    const [haveAsthma, setHaveAsthma] = useState('')

    const [asthmaManagementPlan, setAsthmaManagementPlan] = useState(null)
    const [isAsthmaManagementPlanProvided, setIsAsthmaManagementPlanProvided] = useState(false)

    const [haveHeartConditions, setHaveHeartConditions] = useState('')
    const [heartConditionsDetail, setHeartConditionsDetail] = useState('')
    const [haveVisionImpairment, setHaveVisionImpairment] = useState('')
    const [haveHearingImpairment, setHaveHearingImpairment] = useState('')
    const [haveSeriousIllnessInjury, setHaveSeriousIllnessInjury] = useState('')
    const [hadHospitalizations, setHadHospitalizations] = useState('')
    const [moreAboutImpairmentInjury, setMoreAboutImpairmentInjury] = useState('')
    const [requiredMobiliseAssistanceOrAids, setRequiredMobiliseAssistanceOrAids] = useState('')
    const [mobilityRequirements, setMobilityRequirements] = useState('')
    const [mobilityEquipment, setMobilityEquipment] = useState('')
    const [favouriteMeals, setFavouriteMeals] = useState('')
    const [dislikedFoods, setDislikedFoods] = useState('')
    const [requiredMealtimeAssistance, setRequiredMealtimeAssistance] = useState('')
    const [mealtimeAssistanceDetails, setMealtimeAssistanceDetails] = useState('')

    const [mealtimeManagementPlan, setMealtimeManagementPlan] = useState(null)
    const [isMealtimeManagementPlanProvided, setIsMealtimeManagementPlanProvided] = useState(false)

    const [usingPegNgtToFeed, setUsingPegNgtToFeed] = useState('')
    const [abscondingBehaviours, setAbscondingBehaviours] = useState('')
    const [mentalHealthConcernsTrigger, setMentalHealthConcernsTrigger] = useState('')
    const [mentalHealthConcernsTriggerBehaviour, setMentalHealthConcernsTriggerBehaviour] = useState('')
    const [supportStrategiesMentalHealth, setSupportStrategiesMentalHealth] = useState('')
    const [howYouBestCommunicate, setHowYouBestCommunicate] = useState('')
    const [howToCommunicateWithYou, setHowToCommunicateWithYou] = useState('')
    const [visualSupports, setVisualSupports] = useState('')
    const [bringingVisualSupports, setBringingVisualSupports] = useState('')
    const [givingPermissionToPersonalAssist, setGivingPermissionToPersonalAssist] = useState('')
    const [usingIncontinence , setUsingIncontinence] = useState('')
    const [requiredToiletAssistance , setRequiredToiletAssistance] = useState('')
    const [toiletAssistanceDetail, setToiletAssistanceDetail] = useState('')
    const [requiredShowerAssistance, setRequiredShowerAssistance] = useState('')
    const [requiredDressingAssistance, setRequiredDressingAssistance] = useState('')
    const [showerDressingAssistanceDetails, setShowerDressingAssistanceDetails] = useState('')
    const [requiredHygieneAssistance, setRequiredHygieneAssistance] = useState('')
    const [hygieneAssistanceDetails, setHygieneAssistanceDetails] = useState('')
    const [consentSwimmingParticipation, setConsentSwimmingParticipation] = useState('')
    const [requiredSwimmingAssistance, setRequiredSwimmingAssistance] = useState('')
    const [requiredMenstrualAssistance, setRequiredMenstrualAssistance] = useState('')
    const [menstrualSymptoms, setMenstrualSymptoms] = useState('')
    const [nightTimeRoutine, setNightTimeRoutine] = useState('')
    const [nightTimeRoutineSupportDetails, setNightTimeRoutineSupportDetails] = useState('')
    const [sensoryPreferences, setSensoryPreferences] = useState('')

    useEffect(() => {
        getFormData()
    }, [])

    const getFormData = async () => {
        try {
            setIsRetrievingData(true)
			const { data } = await getCamperFormAboutData()

            setFullName(data.fullName || "")
            setPrimaryContactName(data.primaryContactName || "")
            setPrimaryContactEmail(data.primaryContactEmail || "")
            setPrimaryContactPhone(data.primaryContactPhone || "")
            setPrimaryContactAddress(data.primaryContactAddress || "")
            setPrimaryContactRelation(data.primaryContactRelation || "")
            setSecondaryContactName(data.secondaryContactName || "")
            setSecondaryContactEmail(data.secondaryContactEmail || "")
            setSecondaryContactPhone(data.secondaryContactPhone || "")
            setSecondaryContactRelation(data.secondaryContactRelation || "")
            setMedicareNumber(data.medicareNumber || "")
            setMedicareExpiryDate(data.medicareExpiryDate || "")
            setMedicarePositionOnCard(data.medicarePositionOnCard || "")
            setPrivateHealthInsuranceNumber(data.privateHealthInsuranceNumber || "")
            setPrivateHealthInsuranceExpiryDate(data.privateHealthInsuranceExpiryDate || "")
            setPrivateHealthInsurancePositionOnCard(data.privateHealthInsurancePositionOnCard || "")
            setAmbulanceCoverNumber(data.ambulanceCoverNumber || "")
            setAmbulancePositionOnCard(data.ambulancePositionOnCard || "")
            setHaveAllergies(data.haveAllergies || "")
            setAllergies(data.allergies || "")
            setAllergiesSeverity(data.allergiesSeverity || "")
            setAllergiesSymptoms(data.allergiesSymptoms || "")
            setIsAnaphylaxis(data.isAnaphylaxis || "")

            if(data.anaphylaxisManagementPlan && JSON.parse(data.anaphylaxisManagementPlan).files.length > 0) {
                setIsAnaphylaxisManagementPlanProvided(true)
            }

            setHaveEpilepsySeizures(data.haveEpilepsySeizures || "")
            setLastSeizures(data.lastSeizures || "")

            if(data.epilepsyManagementPlan && JSON.parse(data.epilepsyManagementPlan).files.length > 0) {
                setIsEpilepsyManagementPlanProvided(true)
            }

            setHaveDiabetes(data.haveDiabetes || "")

            if(data.diabetesManagementPlan && JSON.parse(data.diabetesManagementPlan).files.length > 0) {
                setIsDiabetesManagementPlanProvided(true)
            }

            setHaveAsthma(data.haveAsthma || "")

            if(data.asthmaManagementPlan && JSON.parse(data.asthmaManagementPlan).files.length > 0) {
                setIsAsthmaManagementPlanProvided(true)
            }

            setHaveHeartConditions(data.haveHeartConditions || "")
            setHeartConditionsDetail(data.heartConditionsDetail || "")
            setHaveVisionImpairment(data.haveVisionImpairment || "")
            setHaveHearingImpairment(data.haveHearingImpairment || "")
            setHaveSeriousIllnessInjury(data.haveSeriousIllnessInjury || "")
            setHadHospitalizations(data.hadHospitalizations || "")
            setMoreAboutImpairmentInjury(data.moreAboutImpairmentInjury || "")
            setRequiredMobiliseAssistanceOrAids(data.requiredMobiliseAssistanceOrAids || "")
            setMobilityRequirements(data.mobilityRequirements || "")
            setMobilityEquipment(data.mobilityEquipment || "")
            setFavouriteMeals(data.favouriteMeals || "")
            setDislikedFoods(data.dislikedFoods || "")
            setRequiredMealtimeAssistance(data.requiredMealtimeAssistance || "")
            setMealtimeAssistanceDetails(data.mealtimeAssistanceDetails || "")

            if(data.mealtimeManagementPlan && JSON.parse(data.mealtimeManagementPlan).files.length > 0) {
                setIsMealtimeManagementPlanProvided(true)
            }

            setUsingPegNgtToFeed(data.usingPegNgtToFeed || "")
            setAbscondingBehaviours(data.abscondingBehaviours || "")
            setMentalHealthConcernsTrigger(data.mentalHealthConcernsTrigger || "")
            setMentalHealthConcernsTriggerBehaviour(data.mentalHealthConcernsTriggerBehaviour || "")
            setSupportStrategiesMentalHealth(data.supportStrategiesMentalHealth || "")
            setHowYouBestCommunicate(data.howYouBestCommunicate || "")
            setHowToCommunicateWithYou(data.howToCommunicateWithYou || "")
            setVisualSupports(data.visualSupports || "")
            setBringingVisualSupports(data.bringingVisualSupports || "")
            setGivingPermissionToPersonalAssist(data.givingPermissionToPersonalAssist || "")
            setUsingIncontinence(data.usingIncontinence || "")
            setRequiredToiletAssistance(data.requiredToiletAssistance || "")
            setToiletAssistanceDetail(data.toiletAssistanceDetail || "")
            setRequiredShowerAssistance(data.requiredShowerAssistance || "")
            setRequiredDressingAssistance(data.requiredDressingAssistance || "")
            setShowerDressingAssistanceDetails(data.showerDressingAssistanceDetails || "")
            setRequiredHygieneAssistance(data.requiredHygieneAssistance || "")
            setHygieneAssistanceDetails(data.hygieneAssistanceDetails || "")
            setConsentSwimmingParticipation(data.consentSwimmingParticipation || "")
            setRequiredSwimmingAssistance(data.requiredSwimmingAssistance || "")
            setRequiredMenstrualAssistance(data.requiredMenstrualAssistance || "")
            setMenstrualSymptoms(data.menstrualSymptoms || "")
            setNightTimeRoutine(data.nightTimeRoutine || "")
            setNightTimeRoutineSupportDetails(data.nightTimeRoutineSupportDetails || "")
            setSensoryPreferences(data.sensoryPreferences || "")
            
            setIsRetrievingData(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
            setDoesRetrieveFail(true)
			setErrorMessage(errorMsg)
            setIsRetrievingData(false)
		}
	}

    const sendFormData = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        try {
			setIsLoading(true)
			
            const formData = new FormData()

            formData.append("fullName", fullName)
            formData.append("primaryContactName", primaryContactName)
            formData.append("primaryContactEmail", primaryContactEmail)
            formData.append("primaryContactPhone", primaryContactPhone)
            formData.append("primaryContactAddress", primaryContactAddress)
            formData.append("primaryContactRelation", primaryContactRelation)
            formData.append("secondaryContactName", secondaryContactName)
            formData.append("secondaryContactEmail", secondaryContactEmail)
            formData.append("secondaryContactPhone", secondaryContactPhone)
            formData.append("secondaryContactRelation", secondaryContactRelation)
            formData.append("medicareNumber", medicareNumber)
            formData.append("medicareExpiryDate", medicareExpiryDate)
            formData.append("medicarePositionOnCard", medicarePositionOnCard)
            formData.append("privateHealthInsuranceNumber", privateHealthInsuranceNumber)
            formData.append("privateHealthInsuranceExpiryDate", privateHealthInsuranceExpiryDate)
            formData.append("privateHealthInsurancePositionOnCard", privateHealthInsurancePositionOnCard)
            formData.append("ambulanceCoverNumber", ambulanceCoverNumber)
            formData.append("ambulancePositionOnCard", ambulancePositionOnCard)
            formData.append("haveAllergies", haveAllergies)
            formData.append("allergies", allergies)
            formData.append("allergiesSeverity", allergiesSeverity)
            formData.append("allergiesSymptoms", allergiesSymptoms)
            formData.append("isAnaphylaxis", isAnaphylaxis)
            formData.append("haveEpilepsySeizures", haveEpilepsySeizures)
            formData.append("lastSeizures", lastSeizures)
            formData.append("haveDiabetes", haveDiabetes)
            formData.append("haveAsthma", haveAsthma)
            formData.append("haveHeartConditions", haveHeartConditions)
            formData.append("heartConditionsDetail", heartConditionsDetail)
            formData.append("haveVisionImpairment", haveVisionImpairment)
            formData.append("haveHearingImpairment", haveHearingImpairment)
            formData.append("haveSeriousIllnessInjury", haveSeriousIllnessInjury)
            formData.append("hadHospitalizations", hadHospitalizations)
            formData.append("moreAboutImpairmentInjury", moreAboutImpairmentInjury)
            formData.append("requiredMobiliseAssistanceOrAids", requiredMobiliseAssistanceOrAids)
            formData.append("mobilityRequirements", mobilityRequirements)
            formData.append("mobilityEquipment", mobilityEquipment)
            formData.append("favouriteMeals", favouriteMeals)
            formData.append("dislikedFoods", dislikedFoods)
            formData.append("requiredMealtimeAssistance", requiredMealtimeAssistance)
            formData.append("mealtimeAssistanceDetails", mealtimeAssistanceDetails)
            formData.append("usingPegNgtToFeed", usingPegNgtToFeed)
            formData.append("abscondingBehaviours", abscondingBehaviours)
            formData.append("mentalHealthConcernsTrigger", mentalHealthConcernsTrigger)
            formData.append("mentalHealthConcernsTriggerBehaviour", mentalHealthConcernsTriggerBehaviour)
            formData.append("supportStrategiesMentalHealth", supportStrategiesMentalHealth)
            formData.append("howYouBestCommunicate", howYouBestCommunicate)
            formData.append("howToCommunicateWithYou", howToCommunicateWithYou)
            formData.append("visualSupports", visualSupports)
            formData.append("bringingVisualSupports", bringingVisualSupports)
            formData.append("givingPermissionToPersonalAssist", givingPermissionToPersonalAssist)
            formData.append("usingIncontinence", usingIncontinence)
            formData.append("requiredToiletAssistance", requiredToiletAssistance)
            formData.append("toiletAssistanceDetail", toiletAssistanceDetail)
            formData.append("requiredShowerAssistance", requiredShowerAssistance)
            formData.append("requiredDressingAssistance", requiredDressingAssistance)
            formData.append("showerDressingAssistanceDetails", showerDressingAssistanceDetails)
            formData.append("requiredHygieneAssistance", requiredHygieneAssistance)
            formData.append("hygieneAssistanceDetails", hygieneAssistanceDetails)
            formData.append("consentSwimmingParticipation", consentSwimmingParticipation)
            formData.append("requiredSwimmingAssistance", requiredSwimmingAssistance)
            formData.append("requiredMenstrualAssistance", requiredMenstrualAssistance)
            formData.append("menstrualSymptoms", menstrualSymptoms)
            formData.append("nightTimeRoutine", nightTimeRoutine)
            formData.append("nightTimeRoutineSupportDetails", nightTimeRoutineSupportDetails)
            formData.append("sensoryPreferences", sensoryPreferences)

            if(anaphylaxisManagementPlan) {
                formData.append( 
                    "anaphylaxisManagementPlan_file", 
                    anaphylaxisManagementPlan, 
                    anaphylaxisManagementPlan.name 
                )
            }

            if(epilepsyManagementPlan) {
                formData.append( 
                    "epilepsyManagementPlan_file", 
                    epilepsyManagementPlan, 
                    epilepsyManagementPlan.name 
                )
            }

            if(diabetesManagementPlan) {
                formData.append( 
                    "diabetesManagementPlan_file", 
                    diabetesManagementPlan, 
                    diabetesManagementPlan.name 
                )
            }

            if(asthmaManagementPlan) {
                formData.append( 
                    "asthmaManagementPlan_file", 
                    asthmaManagementPlan, 
                    asthmaManagementPlan.name 
                )
            }

            if(mealtimeManagementPlan) {
                formData.append( 
                    "mealtimeManagementPlan_file", 
                    mealtimeManagementPlan, 
                    mealtimeManagementPlan.name 
                )
            }

            await updateCamperFormAboutData(formData)
            setShowSuccessModal(true)
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

    if (isRetrievingData) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        )
    } else if (doesRetrieveFail) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <h5>{errorMessage}</h5>
            </div>
        )
    } else {
        return (
            <>
                <AlertModalClose showModal={showSuccessModal} closeModal={ () => setShowSuccessModal(false)}/>
                <h5>All About me</h5>
                <div style={{ width: "100%"}}>
                    <Form>
                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Full Name</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { fullName } onChange = { (event) => { setFullName(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Primary Contact Name</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { primaryContactName } onChange = { (event) => { setPrimaryContactName(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Primary Contact Email</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { primaryContactEmail } onChange = { (event) => { setPrimaryContactEmail(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Primary Contact Phone</RequiredFieldLabel>
                            <PhoneInput  international countryCallingCodeEditable={false} defaultCountry='AU' placeholder="(212) 123 4567" value={primaryContactPhone} onChange={setPrimaryContactPhone}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Primary Contact Address</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { primaryContactAddress } onChange = { (event) => { setPrimaryContactAddress(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Primary Contact Relationship to Camper</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { primaryContactRelation } onChange = { (event) => { setPrimaryContactRelation(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Secondary Contact Name</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { secondaryContactName } onChange = { (event) => { setSecondaryContactName(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Secondary Contact Email</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { secondaryContactEmail } onChange = { (event) => { setSecondaryContactEmail(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Secondary Contact Phone</RequiredFieldLabel>
                            <PhoneInput  international countryCallingCodeEditable={false} defaultCountry='AU' placeholder="(212) 123 4567" value={secondaryContactPhone} onChange={setSecondaryContactPhone}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Secondary Contact Relationship to Camper</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { secondaryContactRelation } onChange = { (event) => { setSecondaryContactRelation(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Medicare Number</RequiredFieldLabel>
                            <Form.Control type="number" placeholder="Enter text here..." value = { medicareNumber } onChange = { (event) => { setMedicareNumber(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Medicare Expiry Date</RequiredFieldLabel>
                            <Form.Control type="date" value = { medicareExpiryDate } onChange = { (event) => { setMedicareExpiryDate(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Position On Card</RequiredFieldLabel>
                            <Form.Control type="number" placeholder="Enter text here..." value = { medicarePositionOnCard } onChange = { (event) => { setMedicarePositionOnCard(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Private Health Insurance Number</RequiredFieldLabel>
                            <Form.Control type="number" placeholder="Enter text here..." value = { privateHealthInsuranceNumber } onChange = { (event) => { setPrivateHealthInsuranceNumber(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Expiry Date</RequiredFieldLabel>
                            <Form.Control type="date" value = { privateHealthInsuranceExpiryDate } onChange = { (event) => { setPrivateHealthInsuranceExpiryDate(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Position On Card</RequiredFieldLabel>
                            <Form.Control type="number" placeholder="Enter text here..." value = { privateHealthInsurancePositionOnCard } onChange = { (event) => { setPrivateHealthInsurancePositionOnCard(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Ambulance Cover Number</RequiredFieldLabel>
                            <Form.Control type="number" placeholder="Enter text here..." value = { ambulanceCoverNumber } onChange = { (event) => { setAmbulanceCoverNumber(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Position On Card</RequiredFieldLabel>
                            <Form.Control type="number" placeholder="Enter text here..." value = { ambulancePositionOnCard } onChange = { (event) => { setAmbulancePositionOnCard(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you have any food, medication or environmental Allergies</RequiredFieldLabel>
                            <SelectYesNo value={haveAllergies} onChange = { (event) => { setHaveAllergies(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If yes, what are you allergic to?</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { allergies } onChange = { (event) => { setAllergies(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>How severe is your allergic reaction?</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { allergiesSeverity } onChange = { (event) => { setAllergiesSeverity(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>What symptoms might we see if you came into contact with an allergen?</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { allergiesSymptoms } onChange = { (event) => { setAllergiesSymptoms(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Are you anaphylaxis</RequiredFieldLabel>
                            <SelectYesNo value={isAnaphylaxis} onChange = { (event) =>  { setIsAnaphylaxis(event.target.value) } } />
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label style={{marginBottom: "0px"}}>Upload Anaphylaxis Management Plan</Form.Label>
                            <FileInput file={anaphylaxisManagementPlan} onChange={(event) => setAnaphylaxisManagementPlan(event.target.files[0])}/>
                            { isAnaphylaxisManagementPlanProvided ?
                                <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                    <span>File has been provided already. You can upload a new one to update.</span>
                                </Alert>
                            : ""}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you have Epilepsy or Seizures?</RequiredFieldLabel>
                            <SelectYesNo value={haveEpilepsySeizures} onChange = { (event) =>  { setHaveEpilepsySeizures(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If yes, when was your last seizure</Form.Label>
                            <Form.Control type="text" placeholder="Enter text here..." value = {lastSeizures} onChange = { (event) => { setLastSeizures(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label style={{marginBottom: "0px"}}>Upload Epilepsy Management Plan</Form.Label>
                            <FileInput file={epilepsyManagementPlan} onChange={(event) => setEpilepsyManagementPlan(event.target.files[0])}/>
                            { isEpilepsyManagementPlanProvided ?
                                <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                    <span>File has been provided already. You can upload a new one to update.</span>
                                </Alert>
                            : ""}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you have Diabetes Type 1 or Type 2?</RequiredFieldLabel>
                            <SelectYesNo value={haveDiabetes} onChange = { (event) =>  { setHaveDiabetes(event.target.value) } } />
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label style={{marginBottom: "0px"}}>Upload Diabetes Management Plan</Form.Label>
                            <FileInput file={diabetesManagementPlan} onChange={(event) => setDiabetesManagementPlan(event.target.files[0])}/>
                            { isDiabetesManagementPlanProvided ?
                                <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                    <span>File has been provided already. You can upload a new one to update.</span>
                                </Alert>
                            : ""}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you have Asthma</RequiredFieldLabel>
                            <SelectYesNo value={haveAsthma} onChange = { (event) =>  { setHaveAsthma(event.target.value) } } />
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label style={{marginBottom: "0px"}}>Asthma Management Plan</Form.Label>
                            <FileInput file={asthmaManagementPlan} onChange={(event) => setAsthmaManagementPlan(event.target.files[0])}/>
                            { isAsthmaManagementPlanProvided ?
                                <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                    <span>File has been provided already. You can upload a new one to update.</span>
                                </Alert>
                            : ""}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you suffer from any heart conditions? (e.g. high blood pressure, chest pain)</RequiredFieldLabel>
                            <SelectYesNo value={haveHeartConditions} onChange = { (event) =>  { setHaveHeartConditions(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If yes, please provide more detail</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { heartConditionsDetail } onChange = { (event) => { setHeartConditionsDetail(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you have any vision impairment?</RequiredFieldLabel>
                            <SelectYesNo value={haveVisionImpairment} onChange = { (event) =>  { setHaveVisionImpairment(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you have any hearing impairment?</RequiredFieldLabel>
                            <SelectYesNo value={haveHearingImpairment} onChange = { (event) =>  { setHaveHearingImpairment(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Have you had any serious illness or injury?</RequiredFieldLabel>
                            <SelectYesNo value={haveSeriousIllnessInjury} onChange = { (event) =>  { setHaveSeriousIllnessInjury(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Have you had any hospitalisations in the last 12 months?</RequiredFieldLabel>
                            <SelectYesNo value={hadHospitalizations} onChange = { (event) =>  { setHadHospitalizations(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If you answered yes to the last 4 questions, please tell us more</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { moreAboutImpairmentInjury } onChange = { (event) => { setMoreAboutImpairmentInjury(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require assistance or aids to mobilise?</RequiredFieldLabel>
                            <SelectYesNo value={requiredMobiliseAssistanceOrAids} onChange = { (event) =>  { setRequiredMobiliseAssistanceOrAids(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tell us more about your mobility requirements (e.g. what assistance do you need when mobilising? (including the equipment type, brand and name)</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { mobilityRequirements } onChange = { (event) => { setMobilityRequirements(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Will you be bringing your own equipment to profgrams? Or will you require Flying Fox to hire the equipment.</Form.Label>
                            <SelectYesNo value={mobilityEquipment} onChange = { (event) =>  { setMobilityEquipment(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>What are some of your favourite meals?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { favouriteMeals } onChange = { (event) => { setFavouriteMeals(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Are there any foods that you dislike?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { dislikedFoods } onChange = { (event) => { setDislikedFoods(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance with mealtime management?</RequiredFieldLabel>
                            <SelectYesNo value={requiredMealtimeAssistance} onChange = { (event) =>  { setRequiredMealtimeAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If assistance is required, please provide more details</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { mealtimeAssistanceDetails } onChange = { (event) => { setMealtimeAssistanceDetails(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label style={{marginBottom: "0px"}}>if yes,  upload your Mealtime Management Plan</Form.Label>
                            <FileInput file={mealtimeManagementPlan} onChange={(event) => setMealtimeManagementPlan(event.target.files[0])}/>
                            { isMealtimeManagementPlanProvided ?
                                <Alert style={{padding: "5px", marginTop: "15px"}} variant="primary">
                                    <span>File has been provided already. You can upload a new one to update.</span>
                                </Alert>
                            : ""}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you currently use a PEG/NGT to feed?</RequiredFieldLabel>
                            <SelectYesNo value={usingPegNgtToFeed} onChange = { (event) =>  { setUsingPegNgtToFeed(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tell us about any absconding behaviours in the day or night time</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { abscondingBehaviours } onChange = { (event) => { setAbscondingBehaviours(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>What are some of your triggers for your mental health concerns?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { mentalHealthConcernsTrigger } onChange = { (event) => { setMentalHealthConcernsTrigger(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>What are some of the behaviours we might see in response to the triggers?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { mentalHealthConcernsTriggerBehaviour } onChange = { (event) => { setMentalHealthConcernsTriggerBehaviour(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>What are some effective strategies that might support your mental health?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { supportStrategiesMentalHealth } onChange = { (event) => { setSupportStrategiesMentalHealth(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>How do you best communicate?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { howYouBestCommunicate } onChange = { (event) => { setHowYouBestCommunicate(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>How do people best communicate with you?</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { howToCommunicateWithYou } onChange = { (event) => { setHowToCommunicateWithYou(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tell us about some visual supports or AAC you use at home or in the community</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { visualSupports } onChange = { (event) => { setVisualSupports(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Will you be bringing these to your Flying Fox Programs?</Form.Label>
                            <SelectYesNo value={bringingVisualSupports} onChange = { (event) =>  { setBringingVisualSupports(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>I give permission for the Flying Fox Buddies and the Team Members to assist with personal care if required</RequiredFieldLabel>
                            <SelectYesNo value={givingPermissionToPersonalAssist} onChange = { (event) =>  { setGivingPermissionToPersonalAssist(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you use incontinence products?</RequiredFieldLabel>
                            <SelectYesNo value={usingIncontinence} onChange = { (event) =>  { setUsingIncontinence(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance with toileting?</RequiredFieldLabel>
                            <SelectYesNo value={requiredToiletAssistance} onChange = { (event) =>  { setRequiredToiletAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If assistance required, please provide more detail</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { toiletAssistanceDetail } onChange = { (event) => { setToiletAssistanceDetail(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance with showering?</RequiredFieldLabel>
                            <SelectYesNo value={requiredShowerAssistance} onChange = { (event) =>  { setRequiredShowerAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance with dressing or undressing?</RequiredFieldLabel>
                            <SelectYesNo value={requiredDressingAssistance} onChange = { (event) =>  { setRequiredDressingAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If assistance required, please provide more detail</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { showerDressingAssistanceDetails } onChange = { (event) => { setShowerDressingAssistanceDetails(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance with maintaining hygiene?</RequiredFieldLabel>
                            <SelectYesNo value={requiredHygieneAssistance} onChange = { (event) =>  { setRequiredHygieneAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If assistance required, please provide more detail</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { hygieneAssistanceDetails } onChange = { (event) => { setHygieneAssistanceDetails(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you give consent to participate in swimming activities on Flying Fox programs?</RequiredFieldLabel>
                            <SelectYesNo value={consentSwimmingParticipation} onChange = { (event) =>  { setConsentSwimmingParticipation(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance with swimming? (e.g. equipment, 1:1 support)</RequiredFieldLabel>
                            <SelectYesNo value={requiredSwimmingAssistance} onChange = { (event) =>  { setRequiredSwimmingAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Do you require any assistance during your menstrual cycle?</RequiredFieldLabel>
                            <SelectYesNo value={requiredMenstrualAssistance} onChange = { (event) =>  { setRequiredMenstrualAssistance(event.target.value) } } />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tell us more about any symptoms you might experience</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { menstrualSymptoms } onChange = { (event) => { setMenstrualSymptoms(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Please describe your nighttime routine (e.g. comforts, bed time, wake up time?)</RequiredFieldLabel>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { nightTimeRoutine } onChange = { (event) => { setNightTimeRoutine(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>If yes, please provide more detail and include how best to support you if you wake up during the night.</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { nightTimeRoutineSupportDetails } onChange = { (event) => { setNightTimeRoutineSupportDetails(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tell us about your sensory preferences</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter text here..." value = { sensoryPreferences } onChange = { (event) => { setSensoryPreferences(event.target.value) } }/>
                        </Form.Group>

                        
                        {errorMessage !== "" ? 
                        <Alert style={{padding: "5px"}} variant="danger">
                            <span>{errorMessage}</span>
                        </Alert>
                        : ""}

                        <Button disabled={isLoading} onClick={ (event) => sendFormData(event) } variant="primary" type="button">
                        { isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Submit" }
                        </Button>
                    </Form>
                </div>
            </>
        )
    }
}

export default AllAboutMe;
