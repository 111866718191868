import { Table, Form, Alert, Button, Modal} from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import SelectDynamicOptionsValues from '../../common/select-dynamic-options-values'
import RequiredFieldLabel from '../../common/field-label-required'
import { getCamperFormMedicationsData, addCamperFormMedicationsData, updateCamperFormMedicationsData, deleteCamperFormMedicationsData} from '../../../server-api'
import AlertModalClose from '../../common/alert-modal-close-only'

function Medications() {
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const [doesRetrieveFail, setDoesRetrieveFail] = useState(false)

    const [actionType, setActionType] = useState("")
    const [showMedicationModal, setShowMedicationModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [medications, setMedications] = useState([])

    const [itemId, setItemId] = useState(null)
    const [time, setTime] = useState('')
    const [reason, setReason] = useState('')
    const [howToAdminister, setHowToAdminister] = useState('')
    const [dosage, setDosage] = useState('')
    const [critical, setCritical] = useState('')

    const [columnSettings, setColumnSettings] = useState(null)

    useEffect(() => {
        getFormData()
    }, [])

    const getFormData = async () => {
        try {
            setIsRetrievingData(true)

            const { data: { time, critical, itemsData } } = await getCamperFormMedicationsData()

            setColumnSettings({
                time: Object.keys(time.values).map(key => {
                    return {
                        option: time.values[key],
                        value: key
                    }
                }),
                critical: Object.keys(critical.values).map(key => {
                    return {
                        option: critical.values[key],
                        value: key
                    }
                }),
            })

            setMedications(itemsData)
			
            setIsRetrievingData(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
            setDoesRetrieveFail(true)
			setErrorMessage(errorMsg)
            setIsRetrievingData(false)
		}
	}

    const sendFormData = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        try {
			setIsLoading(true)

            const medicationData = {
                itemId,
                time,
                reason,
                howToAdminister,
                dosage,
                critical
            }

            if(actionType === "add") {
                const { data } = await addCamperFormMedicationsData(medicationData)
                setMedications([...medications, {...medicationData, itemId : data.itemId}])
            } else if (actionType === "update") {
                await updateCamperFormMedicationsData(medicationData)
                const updatedMedications = []
                
                medications.forEach(el => {
                    if(el.itemId === itemId) {
                        updatedMedications.push(medicationData)
                    } else {
                        updatedMedications.push(el)
                    }
                })

                setMedications([...updatedMedications])
            }

            setShowMedicationModal(false)
            setShowSuccessModal(true)
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

    const addMedication = () => {
        setShowMedicationModal(true)
        setActionType("add")
        setItemId(null)
        setReason("")
        setHowToAdminister("")
        setDosage("")
        setTime("")
        setCritical("")
    }

    const updateMedication = ({itemId, time, reason, howToAdminister, dosage, critical}) => {
        setShowMedicationModal(true)
        setActionType("update")
        setItemId(itemId)
        setReason(reason)
        setHowToAdminister(howToAdminister)
        setDosage(dosage)
        setTime(time.toString())
        setCritical(critical.toString())
    }

    const deleteMedication = async () => {
        setErrorMessage("")
        try {
			setIsLoading(true)

            await deleteCamperFormMedicationsData(itemId)
            setMedications([...medications].filter(el => el.itemId !== itemId))

            setShowDeleteModal(false)
            setShowSuccessModal(true)
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
        
    }

    const confirmDeleteRecord = (itemId) => {
        setShowDeleteModal(true)
        setItemId(itemId)
    }

    if (isRetrievingData) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        )
    } else if (doesRetrieveFail) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <h5>{errorMessage}</h5>
            </div>
        )
    } else {
        return (
            <>
                <AlertModalClose showModal={showSuccessModal} closeModal={ () => setShowSuccessModal(false)}/>
                <h5>Medications</h5>
                <div style={{ width: "100%"}}>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Time</th>
                                <th>Reason for Medication</th>
                                <th>How is this medication administered/given</th>
                                <th>Dosage</th>
                                <th>Critical</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (medications.length > 0 ? 
                                    medications.map((el, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{el.time !== null ? columnSettings.time.find(ct => ct.value === el.time.toString()).option : ""}</td>
                                                <td>{el.reason}</td>
                                                <td>{el.howToAdminister}</td>
                                                <td>{el.dosage}</td>
                                                <td>{el.critical !== null ? columnSettings.critical.find(cc => cc.value === el.critical.toString()).option : ""}</td>
                                                <td>
                                                    <div style={{display: "flex", justifyContent: "space-around"}}>
                                                        <Button title="Update" onClick={() => updateMedication(el)} variant="secondary" style={{marginRight: "5px"}}><FontAwesomeIcon icon={faEdit}/></Button>{" "}
                                                        <Button title="Delete" onClick={() => confirmDeleteRecord(el.itemId)} variant="danger"><FontAwesomeIcon icon={faTrash}/></Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                : 
                                    <tr>
                                        <td colSpan="7">
                                            No items to display.
                                        </td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </Table>
                    <Button disabled={isLoading} onClick={ (event) => addMedication(event) } variant="primary" type="button">Add Medication</Button>{" "}
                </div>
                <Modal show={showMedicationModal} onHide={() => setShowMedicationModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{actionType === "add" ? "Add Medication" : "Update Medication"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Time</RequiredFieldLabel>
                                <SelectDynamicOptionsValues 
                                    value={time} 
                                    options={columnSettings?.time || []} 
                                    onChange = { (event) =>  { setTime(event.target.value) } } 
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Reason for Medication</RequiredFieldLabel>
                                <Form.Control type="text" placeholder="Enter text here..." value = { reason } onChange = { (event) => { setReason(event.target.value) } }/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>How is this medication administered/given</RequiredFieldLabel>
                                <Form.Control type="text" placeholder="Enter text here..." value = { howToAdminister } onChange = { (event) => { setHowToAdminister(event.target.value) } }/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Dosage</RequiredFieldLabel>
                                <Form.Control type="text" placeholder="Enter text here..." value = { dosage } onChange = { (event) => { setDosage(event.target.value) } }/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <RequiredFieldLabel>Critical</RequiredFieldLabel>
                                <SelectDynamicOptionsValues 
                                    value={critical} 
                                    options={columnSettings?.critical  || []} 
                                    onChange = { (event) =>  { setCritical(event.target.value) } } 
                                />
                            </Form.Group>

                            {errorMessage !== "" ? 
                                <Alert style={{padding: "5px"}} variant="danger">
                                    <span>{errorMessage}</span>
                                </Alert>
                            : ""}

                            <Button disabled={isLoading} onClick={ (event) => sendFormData(event) } variant="primary" type="button">
                            { isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Submit" }
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={showDeleteModal} onHide={ ()=> setShowDeleteModal(false) }>
                    <Modal.Body>Would you like to delete this record? This action cannot be reverted.</Modal.Body>
                    <Modal.Footer style={{padding: "0px"}}>
                        <Button disabled={isLoading} variant="secondary" onClick={ ()=> setShowDeleteModal(false) }>
                            Cancel
                        </Button>
                        <Button disabled={isLoading} variant="primary" onClick={ ()=> deleteMedication() }>
                        { isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Delete" }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>

        )
    }
}

export default Medications;
