import { Modal, Button } from 'react-bootstrap'

function AlertModalClose({ showModal, closeModal }) {
    return (
        <Modal show={showModal} onHide={ closeModal }>
            <Modal.Body>You have successfully updated your record. Thank you!</Modal.Body>
            <Modal.Footer style={{padding: "0px"}}>
                <Button variant="secondary" onClick={ closeModal }>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertModalClose