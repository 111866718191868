function VolunteerCodeOfConduct() {
    return (
        <div style={{height: "300px", padding: "10px", overflowY: "scroll", border: "1px solid #ced4da"}}>
            <p>As a &lsquo;Volunteer&rsquo; of Flying Fox , I will perform my duties honestly and efficiently, respecting the rights of the people we support, colleagues, volunteers and all others I come into contact with during my time working with Camp Sababa. <br />
            <br /> &nbsp;I hereby acknowledge that I will: <br />
            <br /> &nbsp;Work within the law with honesty and integrity <br />
            <br /> &nbsp;Comply with all reasonable and lawful instructions <br />
            <br /> &nbsp;Comply with the policies and procedures of Camp Sababa and the duties conferred upon me in my role as a Buddy <br />
            <br /> &nbsp;Provide a quality service to all campers and the people Camp Sababa supports <br />
            <br /> &nbsp;Ensure that the safety, health and wellbeing of the people we support is upheld to a high standard, in accordance with Camp Sababa&rsquo;s policies and procedures. <br />
            <br /> Recognise the sensitivity of working with vulnerable people and show respect for, and protect, their dignity. <br />
            <br /> &nbsp;Maintain a professional relationship, only, with the people I support. As such, I acknowledge that I must not have a sexual, familial or financial relationship with the people I support. <br />
            <br /> &nbsp;Complete a Working with Children Check and a National Police Check. <br />
            <br /> &nbsp;In turn, I acknowledge that Flying Fox will uphold its obligation to provide me with: <br />
            <br /> &nbsp;Equal opportunities for all voluntary and paid positions <br />
            <br /> &nbsp;Impartial and transparent selection and appointment procedures Up-to-date job descriptions that provide clear statements of duties and expectations Adequate training and equipment to complete my duties <br />
            <br /> &nbsp;Regular and appropriate feedback on performance <br />
            <br /> &nbsp;Effective communication of information <br />
            <br /> &nbsp;Pleasant and safe working conditions <br />
            <br /> &nbsp;Freedom from bullying, harassment or discrimination Appropriate procedures for feedback, complaints, discipline and disputes <br />
            <br /> &nbsp;As a volunteer with Flying Fox, I will work to support people with special needs at all times. I will comply with Camp Sababa&rsquo;s policies and procedures and I will uphold Flying Fox&rsquo;s commitment to protecting its participants and all vulnerable people. <br />
            </p>
        </div>
    )
}

export default VolunteerCodeOfConduct;