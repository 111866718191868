import { Nav, NavItem, Navbar, Image, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import React, { useContext } from 'react'
import { UserContext } from '../../../context/user-context'

function CustomNavbar() {
  const { user } = useContext(UserContext)

    return (
        <Navbar>
            <Navbar.Brand as={Link} to="/dashboard" >
                <Image src='/images/FlyingFoxLogo.png' style={{ width: "200px", height: "auto" }}/>
            </Navbar.Brand>
            <Navbar.Collapse>
              <Nav className="ms-auto">
                <NavItem eventkey={1} href="/">
                    <Dropdown align="end">
                        <Dropdown.Toggle variant="link" bsPrefix="p-0">
                          <span style={{fontSize: "30px", color: "black"}}>
                            <FontAwesomeIcon icon={faBars}/>	
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
							<Dropdown.Item as={Link} to="/dashboard">Home</Dropdown.Item>
							{ user?.userType === "CAMPER" ? 
								<>
									<Dropdown.Item as={Link} to="/forms/camper-about">About Me</Dropdown.Item>
									<Dropdown.Item as={Link} to="/forms/camper-getting-to-know">Getting to Know You</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/forms/camper-medications">Medications</Dropdown.Item>
								</> : "" 
							}

							{ user?.userType === "BUDDY" ?
								<>
									<Dropdown.Item as={Link} to="/forms/buddy-about">About Me</Dropdown.Item>
									<Dropdown.Item as={Link} to="/forms/buddy-check">Checks</Dropdown.Item>
								</> : ""
							}
                        </Dropdown.Menu>
                  </Dropdown>
                </NavItem>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CustomNavbar