function Photography() {
    return (
        <div style={{height: "300px", padding: "10px", overflowY: "scroll", border: "1px solid #ced4da"}}>
            <p>
            <em>We, the undersigned Buddy: <br />
                <br />
            </em>
            </p>
            <ol>
            <li>Agree to participate in the activities of Flying Fox Services Ltd. whether organised or run by Flying Fox Services Ltd. on its own or with or as a part of any other organisation or organisations (including, but not limited to, swimming, kayaking, bowling) (&ldquo;Activities&rdquo;);</li>
            <li>Acknowledge the existence of inherent risks in the Activities and confirm that we are voluntarily taking on and assuming those risks;</li>
            <li>Release and forever discharge, to the extent permitted by law, Flying Fox Services Ltd.&rsquo;s officers, directors, members, volunteers, employees, representatives, agents, executive members, (each a "Released Party") from any and all causes of action, suits, proceedings, claims, demands and liabilities in any way directly or indirectly arising out of or in connection with the Participant&rsquo;s participation in or expulsion from the Activities (including those arising out of death, injury, loss of or damage to property or involving financial or any consequential loss), whether or not arising out of or by reason of the negligence, action or inaction or otherwise of any Released Party (&ldquo;Claims&rdquo;), together with any costs fees and expenses (including legal fees) that may be incurred as a result of any such Claim;</li>
            <li>Agree to abide by all the rules, policies, regulations, and instructions given by or on behalf of Flying Fox Services Ltd. from time to time, governing the Activities (&ldquo;Rules&rdquo;);</li>
            <li>Agree to delegate my authority to the staff and assistants involved. The staff at the Camp may take whatever disciplinary action they deem necessary to ensure the safety, well-being and successful conduct of the Camp and in the conduct of activities and excursions;</li>
            <li>Indemnify each Released Party from and against any Claims suffered or incurred or brought or made against the Released Party: <br />
                <br /> by the Participant; or <br />
                <br /> &nbsp;b. by any other person as a result of the Participant&rsquo;s action and/or failure to comply with the Rules, together with any costs, fees and expenses (including legal fees on an indemnity basis) that may be incurred as a result of any such Claim; <br />
                <br /> 7.Agree that Flying Fox Services Ltd. is authorised and empowered to determine, administer and take all reasonable steps to enforce compliance with, the Rules, and to make such determinations as Flying Fox Services Ltd. deems reasonable or appropriate in relation to the participation of the Participant in the Activities.; <br />
                <br /> &nbsp;8.Acknowledge that we have provided all information necessary for the supervisors to plan safe participation by Participant in the activities, including, if relevant, details of any activities that Participants should not participate in or that should be modified for Participant due to medical or other reasons; <br />
                <br /> &nbsp;9.Agree In the event of any injury or illness to the Participant that, we authorise the Released Party to apply or arrange first aid and to arrange examination by a registered medical practitioner and, if contact with us is impracticable or impossible, to arrange whatever medical treatment the registered medical practitioner considers necessary at that time. We will pay all medical expenses incurred on behalf of the Participant; <br />
                <br /> &nbsp;10.Acknowledge that we voluntarily entered into this Release and Disclaimer and have read and understood all of its terms and agree to be bound by all such terms; and <br />
                <br /> &nbsp;11.Agree that this Release and Disclaimer will be binding on us and our executors, administrators, heirs, next of kin, successors and assigns. <br />
                <br /> &nbsp;This Release and Disclaimer is provided for the benefit of each Released Party and an indemnity provided for the benefit of Flying Fox Services Ltd. and each Released Party. <br />
                <br /> &nbsp;To the extent that this Release and Disclaimer applies to Victorian law, the following warning must be provided: WARNING UNDER THE AUSTRALIAN CONSUMER LAW AND FAIR TRADING ACT 2012 <br />
                <br /> &nbsp;Under the Australian Consumer Law (Victoria), several statutory guarantees apply to the supply of certain goods and services. These guarantees mean that the supplier named on this form is required to ensure that the recreational services it supplies to you: <br />
                <br />
                <ul>
                <li>are rendered with due care and skill; and</li>
                <li>are reasonably fit for any purpose which you, either expressly or by implication, make known to the supplier; and &bull; might reasonably be expected to achieve any result you have made known to the supplier.</li>
                </ul>
            </li>
            </ol>
            <p>Under section 22 of the Australian Consumer Law and Fair Trading Act 2012, the supplier is entitled to ask you to agree that these statutory guarantees do not apply to you. If you sign this form, you will be agreeing that your rights to sue the supplier under the Australian Consumer Law <br />
            <br /> &nbsp;and Fair Trading Act 2012 if you are killed or injured because the services provided were not in accordance with these guarantees, are excluded, restricted or modified in the way set out in this form. <br />
            <br /> &nbsp;NOTE: The change to your rights, as set out in this form, does not apply if your death or injury is due to gross negligence on the supplier&rsquo;s part.Gross negligence, in relation to an act or omission, means doing the act or omitting to do an act with reckless disregard, with or without consciousness, for the consequences of the act or omission. See regulation 5 of the Australian Consumer Law and Fair Trading Regulations 2012 and section 22(3)(b) of the Australian Consumer Law and Fair Trading Act 2012. <br />
            </p>
        </div>
    )
}

export default Photography;