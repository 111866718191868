import { Button, Form, Alert } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { getBuddyFormAboutData, updateBuddyFormAboutData } from '../../../server-api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import '../styles/PhoneInput.css'
import MultipleSelect from '../../common/multiple-select-dynamic-options'
import RequiredFieldLabel from '../../common/field-label-required'
import AlertModalClose from '../../common/alert-modal-close-only'

function AboutMe() {
	const [isLoading, setIsLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

    const [guardianName, setGuardianName] = useState('')
    const [guardianPhone, setGuardianPhone] = useState('')
    const [guardianEmail, setGuardianEmail] = useState('')
    // const [emergencyContactName, setEmergencyContactName] = useState('')
    // const [emergencyContactPhone, setEmergencyContactPhone] = useState('')
    // const [emergencyContactEmail, setEmergencyContactEmail] = useState('')
    const [dietaryReqsAllergies, setDietaryReqsAllergies] = useState('')
    const [religiousCulturalSupport, setReligiousCulturalSupport] = useState('')
    const [medicalInformation, setMedicalInformation] = useState('')
    const [medicalHealthDiagnosis, setMedicalHealthDiagnosis] = useState('')
    const [medicalHealthSupport, setMedicalHealthSupport] = useState('')
    const [otherHealthSupport, setOtherHealthSupport] = useState([])
    const [myHobbies, setMyHobbies] = useState('')
    // const [date, setDate] = useState('')
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const [doesRetrieveFail, setDoesRetrieveFail] = useState(false)

    useEffect(() => {
        getFormData()
    }, [])

    const sendFormData = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        try {
			setIsLoading(true)
			const buddyData = {
                guardianName,
                guardianPhone : guardianPhone || "",
                guardianEmail,
                // emergencyContactName,
                // emergencyContactPhone,
                // emergencyContactEmail,
                dietaryReqsAllergies,
                religiousCulturalSupport,
                medicalHealthDiagnosis,
                medicalInformation,
                medicalHealthSupport,
                otherHealthSupport,
                myHobbies
            }

            await updateBuddyFormAboutData(buddyData)
            setShowSuccessModal(true)
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

	const getFormData = async () => {
        try {
            setIsRetrievingData(true)
			const { data } = await getBuddyFormAboutData()
            setGuardianName(data.guardianName || "")
            setGuardianPhone(data.guardianPhone || "")
            setGuardianEmail(data.guardianEmail || "")
            // setEmergencyContactName(data.emergencyContactName || "")
            // setEmergencyContactPhone(data.emergencyContactPhone || "")
            // setEmergencyContactEmail(data.emergencyContactEmail || "")
            setDietaryReqsAllergies(data.dietaryReqsAllergies || "")
            setReligiousCulturalSupport(data.religiousCulturalSupport || "")
            setMedicalHealthDiagnosis(data.medicalHealthDiagnosis || "")
            setMedicalInformation(data.medicalInformation || "")
            setMedicalHealthSupport(data.medicalHealthSupport || "")
            setOtherHealthSupport(data.otherHealthSupport || [])
            setMyHobbies(data.myHobbies || "")           
            setIsRetrievingData(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
            setDoesRetrieveFail(true)
			setErrorMessage(errorMsg)
            setIsRetrievingData(false)
		}
	}

    if (isRetrievingData) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        )
    } else if (doesRetrieveFail) {
        return (
            <div style={{marginTop:"50px", fontSize: "50px", display: "flex", justifyContent:"center"}}>
                <h5>{errorMessage}</h5>
            </div>
        )
    } else {
        return (
            <>
                <AlertModalClose showModal={showSuccessModal} closeModal={ () => setShowSuccessModal(false)}/>
                <h5>About me</h5>
                <div style={{ width: "100%"}}>
                    <Form>
                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Parent/Guardian Name</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { guardianName } onChange = { (event) => { setGuardianName(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Parent/Guardian Phone</RequiredFieldLabel>
                            <PhoneInput  international countryCallingCodeEditable={false} defaultCountry='AU' placeholder="(212) 123 4567" value={guardianPhone} onChange={setGuardianPhone}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Parent/Guardian Email</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { guardianEmail } onChange = { (event) => { setGuardianEmail(event.target.value) } }/>
                        </Form.Group>
{/* 
                        <Form.Group className="mb-3">
                            <Form.Label>Emergency Contact Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter text here..." value = { emergencyContactName } onChange = { (event) => { setEmergencyContactName(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Emergency Contact Phone</Form.Label>
                            <Form.Control type="number" placeholder="Enter text here..." value = { emergencyContactPhone } onChange = { (event) => { setEmergencyContactPhone(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Emergency Contact Email</Form.Label>
                            <Form.Control type="text" placeholder="Enter text here..." value = { emergencyContactEmail } onChange = { (event) => { setEmergencyContactEmail(event.target.value) } }/>
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Dietary Requirements & Allergies</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { dietaryReqsAllergies } onChange = { (event) => { setDietaryReqsAllergies(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Religious or Cultural Observance Support</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { religiousCulturalSupport } onChange = { (event) => { setReligiousCulturalSupport(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Medical Information</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { medicalInformation } onChange = { (event) => { setMedicalInformation(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Medical Health Diagnosis</RequiredFieldLabel>
                            <Form.Select style={{
                                backgroundColor: medicalHealthDiagnosis === "" ? "rgb(196, 196, 196)" : (medicalHealthDiagnosis === "Yes" ? "rgb(0, 200, 117)" : "rgb(226, 68, 92)"),
                                color: "white"
                            }} value={medicalHealthDiagnosis} onChange = { (event) => { setMedicalHealthDiagnosis(event.target.value) } }>
                                <option style={{ backgroundColor: "white", color: "black" }} value=""></option>
                                <option style={{ backgroundColor: "white", color: "black" }} value="Yes">Yes</option>
                                <option style={{ backgroundColor: "white", color: "black" }} value="No">No</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>Medical Health Support</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { medicalHealthSupport } onChange = { (event) => { setMedicalHealthSupport(event.target.value) } }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Other Health Support (Select all that applies)</Form.Label>
                            <MultipleSelect value={otherHealthSupport} options={["I have a tracheostomy", "I take medication", "I use a feeding tube"]} onChange={setOtherHealthSupport}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <RequiredFieldLabel>My hobbies are...</RequiredFieldLabel>
                            <Form.Control type="text" placeholder="Enter text here..." value = { myHobbies } onChange = { (event) => { setMyHobbies(event.target.value) } }/>
                        </Form.Group>

                        {errorMessage !== "" ? 
                        <Alert style={{padding: "5px"}} variant="danger">
                            <span>{errorMessage}</span>
                        </Alert>
                        : ""}

                        <Button disabled={isLoading} onClick={ (event) => sendFormData(event) } variant="primary" type="button">
                        { isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Submit" }
                        </Button>
                    </Form>
                </div>
            </>
        )
    }
}

export default AboutMe;
