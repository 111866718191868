import { Col, Container, Row, Card, Button, Form, Alert } from 'react-bootstrap'
import { useState, useContext } from 'react'
import { login, deviceOtp } from '../server-api'
import { UserContext } from '../context/user-context'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Login() {
	const { setUser } = useContext(UserContext)

    const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [cookies] = useCookies(['deviceAuth'])

	let navigate = useNavigate()

	const loginFn = async (event) => {
		event.preventDefault()
        setEmail(email.trim())
		setPassword(password.trim())
		setErrorMessage("")

        const loginData = {
			email: email,
			password: password,
		}

		try {
			setIsLoading(true)
			const { data: { user, token } } = await login(loginData)
			axios.defaults.headers.common['Authorization'] = token
			const { data: { isDeviceAuthorized } } = await deviceOtp(cookies.deviceAuth)
			setUser({...user, deviceAuthed: isDeviceAuthorized})

			navigate('/dashboard')
			setIsLoading(false)
		} catch (err) {
			let errorMsg = err?.response?.data?.message || "Failed to connect to server."
			setErrorMessage(errorMsg)
			setIsLoading(false)
		}
	}

	return (
		<Container fluid style= {
			{
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center',
				backgroundColor: '#e9ecef',
				height: '100vh',
				flexDirection: 'column'
			}
		}>
			<Row style={{ width: "auto", textAlign: "center", padding: "20px"}}>
				<Col>
					<h1>Login</h1>
					<h5>Welcome to the Flying Fox Portal</h5>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<Card style={{ width: "auto", minWidth: "350px" }}>
						<Card.Body>
							<Card.Title style={{ fontSize: "15px", marginBottom: "15px" }}>Please login your account</Card.Title>
							<Form>
                            <Form.Group className="mb-3">
								<Form.Control type="text" placeholder="Email" value = { email } onChange = { (event) => { setEmail(event.target.value) } }/>
								</Form.Group>

								<Form.Group className="mb-3">
								<Form.Control type="password" placeholder="Password" value = { password } onChange = { (event) => { setPassword(event.target.value) } }/>
								</Form.Group>

								{errorMessage !== "" ? 
								<Alert style={{padding: "5px"}} variant="danger">
									<span>{errorMessage}</span>
								</Alert>
								: ""}

								<Button disabled={isLoading} onClick={ (event) => loginFn(event) } variant="primary" type="button">
								{ isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Login" }
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Container>
	);
}

export default Login;
