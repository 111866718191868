import { Col, Container, Row } from 'react-bootstrap'
import React, { useContext } from 'react'
import { UserContext } from '../../context/user-context'
import { Navigate } from 'react-router-dom'
import Navbar from '../common/navbar'

function Dashboard() {
    const { user } = useContext(UserContext)

    return !user.deviceAuthed ? <Navigate to="/otp" /> : (
        <Container>
            <Navbar/>
            <Row style={{ marginTop: "50px"}}>
                <Col>
                    <h1>Welcome to the Flying Fox</h1>
                </Col>
            </Row>
            {user ? 
                <Row>
                    <Col>
                        <span>Hi {user?.name}! </span> 
                    </Col>
                </Row>
            : "" } 
        </Container>
    )
}

export default Dashboard;
