import { Col, Container, Row, Card, Button, Form, Alert } from 'react-bootstrap'
import { useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { register } from '../server-api'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Register() {
	const [searchParams, ] = useSearchParams();
	const [password, setPassword] = useState('')
  	const [confirmPassword, setConfirmPassword] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
    let navigate = useNavigate();

	const registerFn = async (event) => {
		event.preventDefault()
		setPassword(password.trim())
    	setConfirmPassword(confirmPassword.trim())

		if(password !== confirmPassword) {
			setErrorMessage("Password and confirm password should match.")
			return
		} else {
			setErrorMessage("")
		}
		

		const data = {
			token: searchParams.get("token"),
			password: password,
		}

		try {
			setIsLoading(true)
			await register(data)
			navigate(`/login`)
			setIsLoading(false)
		} catch (err) {
			setErrorMessage(err.response.data.message)
			setIsLoading(false)
		}
	}

	return (
		<Container fluid style= {
			{
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center',
				backgroundColor: '#e9ecef',
				height: '100vh',
				flexDirection: 'column'
			}
		}>
			<Row style={{ width: "auto", textAlign: "center", padding: "20px"}}>
				<Col>
					<h1>Registration</h1>
					<h5>Welcome to the Flying Fox Portal</h5>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<Card style={{ width: "auto", minWidth: "350px" }}>
						<Card.Body>
							<Card.Title style={{ fontSize: "15px", marginBottom: "15px" }}>Complete the below fields to create your account</Card.Title>
							<Form>
								<Form.Group className="mb-3">
								<Form.Control type="password" placeholder="Password" value = { password } onChange = { (event) => { setPassword(event.target.value) } }/>
								</Form.Group>

								<Form.Group className="mb-3">
								<Form.Control type="password" placeholder="Confirm Password" value = { confirmPassword } onChange = { (event) => { setConfirmPassword(event.target.value) } }/>
								</Form.Group>

								{errorMessage !== "" ? 
								<Alert style={{padding: "5px"}} variant="danger">
									<span>{errorMessage}</span>
								</Alert>
								: ""}

								<Button disabled={isLoading} onClick={ (event) => registerFn(event) } variant="primary" type="button">
								{ isLoading ? <span>Loading <FontAwesomeIcon icon={faSpinner} spin/></span> : "Register" }
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Container>
	);
}

export default Register;
