import { Form } from 'react-bootstrap'

function SelectYesNo({ value, onChange }) {
    return (
        <Form.Select style={{
            maxWidth: "300px",
            color: value === "" ? "gray" : "black",
        }}
        value={value} onChange = {onChange }>
            <option style={{display: "none"}} value="">Select your answer</option>
            <option style={{color:"black"}} value="Yes">Yes</option>
            <option style={{color:"black"}} value="No">No</option>
        </Form.Select>
    )
}

export default SelectYesNo